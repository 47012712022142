#confiance {
  background-color: $dark-color;
  padding: 50px 0;

  .wrapper {
    justify-content: center;
    text-align: center;
    height: 100%;
    align-items: center;
    padding-bottom: 50px; }

  .confiance_container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    width: 100%; }

  .confiance_box {
    border: 1px solid #ffffff;
    height: 100%;
    // flex: 0 0 80%
    flex: 0 1 100%;
    padding: 40px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 320px;
    min-width: 320px;
    @media all and (max-width: 320px) {
      min-width: 265px;
      max-width: 265px; }
    &::before {
      content: '';
      background-color: $dark-color;
      background-image: url("/img/guillemets.svg");
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center;
      height: 60px;
      width: 60px;
      position: absolute;
      top: -39px;
      left: -13px; }
    .partner_logo_author {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      @include mq(mobile) {
        flex-direction: column; } }
    .partner_author {
      color: #ffffff;
      display: flex;
      align-items: center;
      width: 40%;
      @include mq(mobile) {
        width: 100%;
        justify-content: flex-end; } }
    .partner-photo {
      max-height: 187px;
      display: flex;
      img {
        width: 100%;
        height: auto;
        object-fit: cover; } }

    .video {
      &_img {
        // display: flex
        width: 100%;
        position: relative;
        max-width: 300px;
        margin: 0 auto;
        img.video {
          width: 100%;
          height: auto; } }

      .video {
        width: 100%;
        .photo_img {
          .video {
            height: 100%;
            object-fit: cover; } } }
      &_button {
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        width: 100px;
        height: 100px;
        background: none;
        border: none;
        cursor: pointer;
        text-align: center; } } }

  .confiance_logo {
    margin-bottom: 10px;
    width: auto;
    height: 100%;
    max-height: 50px;
    padding: 0px;
    object-fit: contain;
    align-self: flex-start;
    max-width: 150px; }

  .white {
    color: $light-color; } }
