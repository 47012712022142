.mention {
  display: flex;
  margin: 160px auto 0;
  width: 85%;
  @include mq(mobile) {
    margin: 120px auto 0; }
  h1 {
    @include fontSize(30px);
    @include mq(mobile) {
      @include fontSize(20px); }
    &.outline {
      font-weight: 800;
      @include fontSize(50px);
      color: $light-color;
      -webkit-text-stroke: 1px;
      -webkit-text-stroke-color: $dark-color;
      text-transform: uppercase;
      line-height: 1.2;
      margin-left: 5px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        color: white;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
      @include mq(tablet) {
        font-size: 5.2vw; }
      @include mq(mobile) {
        @include fontSize(30px); } } }
  .section_content {
    width: 100%; } }
