#studio-digital {
  width: 100%;
  padding: 70px 0px 0px 0px;
  @include mq(tablet) {
    overflow: hidden; }
  @include mq(mobile) {
    padding: 20px 0px;
    margin-bottom: 40px; }
  .wrapper {
    align-items: center; }
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
    &:nth-child(2) {
      @include mq(mobile) {
        margin-bottom: 0; } }
    @include mq(mobile) {
      flex-wrap: wrap;
      justify-content: left; }

    br {
      display: none; } }

  .video {
    &_img {
      display: inline-block;
      width: 100%;
      position: relative;
      margin-top: 80px;
      margin-left: 70px;
      @media screen and (min-width: 1280px) {
        width: 600px;
        height: auto;
        margin-left: 0px; }
      @include mq(mobile) {
        margin: 70px 0px 0px 0px;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto; }
      &::before {
        content: '';
        display: block;
        background: url("/img/GreenGraphicLines.svg") no-repeat;
        position: absolute;
        top: -80px;
        left: -90px;
        width: 200px;
        height: 155px;
        @include mq(mobile) {
          background: url("/img/traits.svg") no-repeat;
          background-position: center;
          width: 124px;
          height: 97px;
          left: calc(50% - 62px);
          top: -50px; } }
      .video {
        width: 100%;
        object-fit: contain;
        .photo_img {
          .video {
            height: 100%;
            object-fit: cover; } } }
      .image-youtube {
        height: auto;
        display: inline-flex;
        width: 100%;
        align-items: center; } }


    &_button {
      position: absolute;
      left: calc(50% - 20px);
      background: none;
      border: none;
      cursor: pointer; } }

  .box-text {
    margin: 50px 0px 0px 40px;
    @media screen and (min-width: 1280px) {
      width: 600px; }
    @include mq(mobile) {
      width: 100%;
      margin: 0px; }
    br {
      display: block; }
    &--bottom {
      margin-top: 50px;
      @media all and (min-width: 1280px) {
        margin: 50px 50px 0 0; }
      @include mq(mobile) {
        order: 2;
        margin-bottom: 0; } }
    &--top {
      margin-top: 70px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding-left: 20px; }
      @include mq(mobile) {
        margin-left: 0px; } } }
  p {
    font-weight: $medium;
    @include fontSize(15px);
    line-height: 25px;
    padding-top: 30px;
    padding-bottom: 20px; }

  .photo_img {
    display: flex;
    width: 100%;
    max-width: 460px;
    // height: 285px
    position: relative;
    margin-top: 80px;
    margin-left: 70px;
    display: inline-block;
    @media screen and (min-width: 1280px) {
      width: 600px;
      height: auto;
      margin-left: 0; }
    @include mq(mobile) {
      order: 1;
      width: 100%;
      margin-top: 0;
      margin-left: 0; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    &::before {
      content: '';
      display: block;
      background: url("/img/GreenGraphicLines.svg") no-repeat;
      position: absolute;
      top: -80px;
      left: -90px;
      width: 200px;
      height: 155px;
      @include mq(mobile) {
        background: url("/img/traits.svg") no-repeat;
        background-position: center;
        width: 124px;
        height: 97px;
        left: calc(50% - 62px);
        top: -20px; } }
    .btn {
      width: 239px;
      height: 62px;
      justify-content: center;
      align-items: center;
      display: flex; } }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    li {
      font-family: Poppins;
      font-weight: 400;
      padding-bottom: 20px; } } }

