.availabilities {
  #rooms {
    .wrapper {
      margin-top: 160px;
      @include mq(mobile) {
        margin-top: 120px; } }

    .rooms-subtitle {
      margin-top: 15px;
      max-width: 50%;

      @include mq(mobile) {
        max-width: 100%; } }

    h2 {
      font-size: 4rem;
      &.shadow-inline {
        text-shadow: -2px 2px $black-color; } }

    .rooms {
      display: flex;
      flex-wrap: wrap;

      @include mq(mobile) {
        margin-top: 60px; }

      .column {
        flex: 50%;
        padding: 60px 25px;
        display: flex;
        flex-direction: column;
        @include mq(mobile) {
          flex: 100%;
          align-items: center !important;
          padding: 0; }

        &:first-child {
          align-items: flex-end;
          @include mq(('screen and ( max-width: 850px )')) {
            align-items: flex-start; } }
        &:last-child {
          align-items: flex-start;
          margin-top: -150px;
          @include mq(('screen and ( max-width: 850px )')) {
            align-items: flex-end; }
          @include mq(mobile) {
            margin-top: 0; } } } }

    .card {
      max-width: 385px;
      margin-bottom: 60px;
      box-shadow: rgba($dark-color, 0.1) 0 20px 40px 0;

      //+mq(('screen and ( max-width: 850px )'))
      //  max-width: 300px
      @include mq(mobile) {
        max-width: 385px; }

      &-illu {
        background-color: rgba($dark-color, 0.4);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 220px; }
      &-content {
        padding: 30px;

        h2 {
          margin-top: 0;
          margin-bottom: 25px; }
        p {
          margin-bottom: 10px; }

        .lightColor {
          color: rgba($dark-color, 0.4); } }
      &-date {
        position: relative;
        display: inline-block;
        padding: 15px 50px 15px 15px;
        border: 1px solid rgba($dark-color, 0.4);
        width: 200px;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -11px;
          height: 20px;
          width: 19px;
          background-image: url("/img/Calendar.svg");
          background-position: right;
          background-size: contain;
          background-repeat: no-repeat; } }

      hr {
        height: 4px;
        background-color: $black-color;
        margin: 15px 0 30px 0; } } }


  .modal__avail {
    &.hide, &-form.hide {
      display: none; }

    .inputs-container {
      display: flex;
      margin-top: 25px;
      margin-bottom: 30px;

      @include mq(mobile) {
        flex-direction: column; }

      & > div {
        margin-right: 10px;
        @include mq(mobile) {
          margin-right: 0;
          margin-bottom: 15px; } } }

    label {
      color: rgba($dark-color, 0.4);
      display: block;
      margin-bottom: 7px;
      @include fontSize(14px); }
    input, select {
      border: 1px solid rgba($dark-color, 0.1);
      padding: 15px 30px 15px 10px;
      outline: 0; }
    select {
      cursor: pointer;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 0;
      background-color: #ffffff;
      background-image: url("/img/dropdown.svg");
      background-repeat: no-repeat;
      background-size: 8px;
      background-position-x: calc(100% - 8px);
      background-position-y: center; } }

  .container_text-date {
    margin-top: 30px;
    float: left;

    img {
      position: relative;
      height: 20px;
      width: 20px;
      vertical-align: middle;
      margin-right: 10px; } }

  .center {
    text-align: center; }
  .validateDate {
    margin-top: 20px;
    padding: 10px 20px 10px 20px; }

  #form {
    padding-top: 0;
    background: none;
    form {
      margin-top: 35px; }
    p {
      @include fontSize(14px); }
    hr {
      width: 100%;
      background-color: rgba($dark-color, 0.4);
      margin: 20px 0 25px 0; }
    .section__wrapper {
      width: 100%; }

    .toggle-content {
      float: right;
      margin-top: 20px;
      padding: 10px 20px 10px 20px; }

    .btn[type='submit'] {
      background: none;
      border: 0;
      display: inline-block;
      box-shadow: none;
      width: auto;
      height: auto;
      margin-top: 45px;

      span {
        padding: 10px 20px 10px 20px; } } } }
