.confirmation {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;

  .wrapper {
    margin: auto; }

  .section_content {
    display: flex;
    border: 2px solid black;
    text-align: center;
    padding: 40px 140px;
    box-shadow: 6px 4px;
    flex-direction: column;

    @include mq(tablet) {
      padding: 40px 80px; }
    @include mq(mobile) {
      padding: 40px 20px; }

    .big-title {
      margin-bottom: 20px; } }

  .desabonnement {
    .section_content {
      @include mq(mobile) {
        padding: 40px 60px;
        width: 90%; } } } }
