.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  background-image: none; }
.ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
  font-weight: 800; }
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0; }

.datepicker {
  .ui-widget {
    @include mq(mobile) {
      flex-direction: column;
      align-items: center; }

    .ui-icon {
      width: 16px;
      height: 16px;
      display: block;
      text-indent: -99999px;
      overflow: hidden;
      background-repeat: no-repeat;
      top: 50%;
      margin-top: -11px; }
    &.ui-widget-content {
      border: 0;
      border-bottom: 1px solid rgba($dark-color, 0.1);
      padding: 0;
      width: 100% !important;
      display: flex !important;
      flex-wrap: wrap; }

    .ui-widget-header {
      position: relative;
      font-family: Poppins;
      text-transform: capitalize;
      background: #ffffff;
      border: 0;
      border-bottom: 1px solid rgba($dark-color, 0.1);
      padding: 0 0 10px 0; }

    table {
      margin-top: 20px;
      margin-bottom: 30px;
      @include fontSize(14px);

      thead th {
        padding-bottom: 20px; } }
    th {
      font-family: Poppins;
      @include fontSize(14px);
      font-weight: 600; }
    td {
      a {
        text-decoration: none; }
      .ui-state-default {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 41px;
        height: 41px;
        border: 0;
        background-color: #ffffff;
        color: $dark-color;
        font-weight: 400; }

      .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
        background-color: #fff; }
      .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
        background-color: $green;
        color: #ffffff;
        border-radius: 50%; } }

    &.ui-datepicker-multi .ui-datepicker-group {
      &.ui-datepicker-group-last {
        padding-left: 20px; } } } }

.ui-datepicker-prev, .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;

  span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px; }

  &.ui-state-hover {
    border: 0 !important;
    background-color: transparent !important;
    top: 2px !important; }
  &:hover {
    cursor: pointer; } }

.ui-datepicker-prev {
  left: 2px;

  span {
    background-image: url("/img/arrow-left.png") !important;
    background-size: contain;
    background-position: 0px 0px !important; }
  &.ui-state-hover {
    left: 2px !important; } }

.ui-datepicker-next {
  right: 2px;

  span {
    background-image: url("/img/arrow-right.png") !important;
    background-size: contain;
    background-position: 0px 0px !important; }
  &.ui-state-hover {
    right: 2px !important; } }

.ui-timepicker-list {
  display: block;

  li {
    padding: 7px 0 7px 10px !important; } }
