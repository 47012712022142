html {
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth; }

html, body {
  margin: 0;
  padding: 0; }

*, *::before, *::after {
  box-sizing: border-box;

  &:focus {
    outline: 0;
    outline: none; } }

body {
  font-family: $body-font;
  color: $dark-color;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
  @include fontSize(16px);
  scroll-behavior: smooth; }

button {
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  text-align: left;
  color: inherit;
  cursor: pointer; }

.visually-hidden {
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0; }

section {
  width: 100%;
  height: 100%;
  min-height: 500px; }

.wrapper {
  width: 90%;
  max-width: 945px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    max-width: 1200px; } }

// TYPO //
h1,h2 {
  font-weight: $extrabold;
  text-transform: uppercase;
  font-weight: $dark-color; }

h1 {
  @include fontSize(67px);
  margin-bottom: 10px;
  @include mq(tablet) {
    font-size: 6vw; }
  @include mq(mobile) {
    @include fontSize(40px); } }


.outline {
  h1, h2, h3 {
    font-weight: 800;
    @include fontSize(50px);
    color: $light-color;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: $dark-color;
    text-transform: uppercase;
    line-height: 1.2;
    margin-left: 5px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      color: white;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
    @include mq(tablet) {
      font-size: 5.2vw; }
    @include mq(mobile) {
      @include fontSize(34px); } } }

h2 {
  @include fontSize(30px);
  @include mq(mobile) {
    @include fontSize(20px); }
  &.outline {
    font-weight: 800;
    @include fontSize(50px);
    color: $light-color;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: $dark-color;
    text-transform: uppercase;
    line-height: 1.2;
    margin-left: 5px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      color: white;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
    @include mq(tablet) {
      font-size: 5.2vw; }
    @include mq(mobile) {
      @include fontSize(38px); } }
  &.shadow-inline {
    text-shadow: -2px 2px #ffffff;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      color: white;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
    @include mq(mobile) {
      margin-top: 60px; } }

  &.modal-title {
    color: white;
    background: $orange;
    padding: 5px;
    margin-bottom: 40px;
    display: inline-block; }

  span.green-bg {
    position: relative;
    display: inline-block;
    &::after {
      content: '';
      background: $green;
      position: absolute;
      left: -5px;
      top: 5px;
      height: calc(100% - 10px);
      width: calc(100% + 10px);
      z-index: -1; } }

  span.green-typewriter {
    position: relative;
    &::after {
      content: '';
      background: $green;
      position: absolute;
      left: 0px;
      top: -5px;
      height: calc(100% + 10px);
      width: 100%;
      z-index: -1; } } }

h3, .title3 {
  @include fontSize(25px);
  font-weight: $bold;
  color: $black-color;
  z-index: 3;
  position: relative;
  @include mq(mobile) {
    @include fontSize(20px);
    line-height: 1.4; }

  &.outline-shadow {
    @include fontSize(36px);
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: $dark-color;
    text-transform: uppercase;
    line-height: 1.2;
    color: $light-color;
    font-weight: 900;
    text-shadow: 3px 3px #1D1D1B;
    @include mq(mobile) {
      margin-top: 30px; } }

  &.box-text-title {
    font-weight: $extrabold;
    @include fontSize(50px);
    color: $light-color;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: $black-color;
    text-transform: uppercase;
    line-height: 60px;
    margin-top: 55px; }

  &.podium__title {
    position: relative;
    @include mq(mobile) {
      padding-left: 50px; }

    &::before {
      content: '';
      background: url("/src/img/Glyph.svg") no-repeat;
      display: block;
      width: 80px;
      height: 70px;
      position: absolute;
      left: -85px;
      top: -20px;
      @include mq(mobile) {
        width: 44px;
        left: 0px;
        background-size: cover;
        height: 40px;
        top: -5px; } } }

  span.green-bg {
    position: relative;
    &::after {
      content: '';
      background: $green;
      position: absolute;
      left: -2.5px;
      top: -2.5px;
      height: calc(100% + 5px);
      width: calc(100% + 5px);
      z-index: -1;
      @include mq(mobile) {
        top: 0;
        height: 100%;
        border: 0; } } } }
h4 {
  line-height: 1.3;
  &.box-title {
    font-size: 24px;
    font-weight: $semibold;
    color: $light-color;
    margin-bottom: 23px; } }

.green-bg {
  position: relative;
  &::after {
    content: '';
    background: $green;
    position: absolute;
    left: -5px;
    top: 5px;
    height: calc(100% - 10px);
    width: calc(100% + 10px);
    z-index: -1; } }
p {
  color: black;
  @include fontSize(15px);
  font-weight: $medium;
  line-height: 1.3;
  font-family: Poppins;
  &.medium {
    @include fontSize(14px);
    font-weight: $regular; }
  &.small {
    @include fontSize(12px);
    font-weight: 400; }
  &.big {
    @include fontSize(16px); } }

.white {
  color: $light-color; }

h2.modal-title, h3.modal-title {
  color: white;
  background: #EC6608;
  padding: 5px;
  margin-bottom: 40px;
  display: inline-block; }

#studio-digital {
  @include mq(mobile) {
    transform: translateY(30px); } }

#bandeau-flyer {
  transform: translateY(-80px);
  @include mq(mobile) {
    transform: translateY(10px); } }

#studio-connecte {
  transform: translateY(-50px); }

#tournage {
  transform: translateY(-50px); }

#partenaires {
  transform: translateY(-45px);
  @include mq(mobile) {
    transform: translateY(15px); } }

#quatre-espaces {
  transform: translateY(-60px); }

.box.box-primary th {
  color: #3c8dbc; }

.table-detail {
  padding: 30px; }
