
.form-flyer {
  background: none;
  padding: 0;
  .form-title {
    background-color: $orange;
    color: #ffffff;
    padding: 5px; }
  .section {
    &__container {
      flex-direction: column; }
    &__box {
      width: 100%;
      @include mq(mobile) {
        width: 100%; } } }

  .formfield--50 {
    flex: 0 1 49%;
    border: 1px solid #DDDDDD;
    margin: 0px 0px 15px 0px;
    font-size: 14px;
    text-indent: 10px;
    background: #FFFFFF;
    color: $dark-color;
    font-weight: 500;
    width: 49%;
    @include mq(mobile) {
      width: 100%; } }

  .form-bottom {
    display: flex;
    justify-content: space-between;
    @include mq(mobile) {
      flex-direction: column; } }

  .formfield--100 {
    width: 99%; }

  .formfield--25 {
    width: 25%;
    @include mq(mobile) {
      width: 100%; } } }
