.omnilive {
  font-family: Poppins;
  position: relative;
  margin-top: 45px;

  &_box {
    margin-bottom: 40px;
    width: 82%;
    @include mq(tablet) {
      width: 100%;
      margin: 0 auto;
      display: flex; }

    .outline {
      padding-bottom: 25px;
      h2 {
        @include mq(mobile) {
          font-size: 45px; } } } }

  strong {
    font-weight: bold; }

  &_container {
    position: relative;
    &::before {
      content: '';
      display: block;
      background: url("/img/GreenGraphicLines.svg") no-repeat;
      position: absolute;
      top: -60px;
      left: calc(50% - 125px);
      width: 200px;
      height: 155px;
      z-index: 1;
      @include mq(tablet) {
        display: none; } }

    .jt-kube {
      display: flex;
      flex-wrap: wrap;
      @include mq(tablet) {
        flex-direction: column; }

      .video-studio {
        position: relative;
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-top: 44px;
        @include mq(tablet) {
          width: 100%;
          padding-bottom: 60px;
          align-items: center; }

        .title {
          display: flex;
          position: absolute;
          top: -3%;
          left: calc(50% - 140px);
          @include mq(mobile) {
            left: calc(50% - 100px); }
          h3 {
            color: $light-color;
            background-color: $orange;
            justify-content: center;
            width: 195px;
            display: flex;
            padding: 15px 0;
            @include mq(mobile) {
              padding: 5px 0; } } }

        .video_img {
          position: relative;
          width: 90%;
          .video-img {
            width: 100%;
            // height: auto
            height: 303px;
            object-fit: cover; }
          .video_button {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 65%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            button {
              color: #ffffff;
              border: 1px solid #ffffff;
              margin-bottom: 30px;
              margin-top: auto;
              padding: 10px;
              text-transform: uppercase;
              font-size: 10px;
              font-weight: bold; } } }


        .blocfields {
          margin-left: 30px;
          width: 80%;
          .blocfield {
            border-top: 2px solid #000000;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            p {
              strong {
                font-size: 14px;
                @include mq(tablet) {
                  display: flex;
                  align-items: baseline;
                  flex-direction: column; } }
              span.orange_color {
                strong {
                  font-size: 20px; } } }
            &:first-child {
              border-top: none;
              span.orange_color {
                font-size: 30px; } } } } }

      .video-studio-right {
        // margin-top: 22px
        // &:last-child
        //// margin-top: 44px
        .title {
          @include mq(mobile) {
            left: calc(50% - 115px); }
          h3 {
            width: 235px; } } } }

    .bloc-avail {
      position: relative;
      padding: 30px 50px;
      border: 10px solid $green;
      margin-top: 50px;
      margin-bottom: 75px;

      @include mq(mobile) {
        padding: 30px 25px; }

      h2 {
        font-weight: 800;
        text-transform: none;

        @include mq(mobile) {
          font-size: 3.7rem; } }

      .avail_text {
        width: 53%;
        @include mq(mobile) {
          width: 100%;
          padding-bottom: 300px; }
        @include mq(('screen and ( max-width: 450px)')) {
          padding-bottom: 200px; }

        .avail-link {
          position: relative;
          display: inline-block;
          padding: 10px 20px 10px 20px;
          margin-left: 50px;
          margin-top: 13px;

          @include mq(mobile) {
            margin-left: 0; }

          &:before {
            content: "";
            position: absolute;
            width: 40px;
            height: 36px;
            left: -50px;
            top: 50%;
            margin-top: -18px;
            background-image: url("/img/Glyph-black_outline.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            @include mq(mobile) {
              content: none; } } } }


      .avail_illu {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 47%;
        height: calc(100% + 20px);
        background-image: url("/img/5ffc6f45b7e32_epokaup.png");
        background-position: top left;
        background-size: cover;
        background-repeat: no-repeat;

        @include mq(mobile) {
          height: 300px;
          width: calc(100% + 20px);
          top: auto;
          bottom: -10px;
          left: -10px;
          right: auto;
          background-image: url("/img/5ffc6f45b7e32_epokaup-mobile.png"); }
        @include mq(('screen and ( max-width: 450px )')) {
          height: 200px; } } }

    .bloc-bottom {
      margin-top: 35px;

      .title_bottom {
        display: flex;
        width: 65%;
        line-height: 1.3;
        padding-bottom: 30px;
        @include mq(tablet) {
          width: 100%; } }

      .text_bottom {
        padding-left: 40px;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 40px;
        @include mq(tablet) {
          padding-left: 20px;
          flex-wrap: inherit;
          flex-direction: column; }
        p {
          display: block;
          flex: 0 1 50%;
          padding-bottom: 20px;
          strong {
            position: relative;
            display: flex;
            &::before {
              content: '';
              background: url("/img/fleche-droite.png") no-repeat;
              height: 16px;
              width: 10px;
              position: absolute;
              left: -15px; } } } } }

    .footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 45px;
      .footer-text {
        text-align: center;
        border-bottom: 1px solid #000000;
        padding-bottom: 60px;
        position: relative;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          @include mq(mobile) {
            display: block; }
          &::before {
            background: url("/img/background-footer.png");
            background-repeat: repeat no-repeat;
            width: 100%;
            height: 100px;
            position: absolute;
            left: 0;
            content: "";
            top: -30px;
            @include mq(mobile) {
              height: 140px; } } } }

      .footer-social {
        display: flex;
        padding-top: 25px;
        width: 65%;
        margin: 0 auto;
        @include mq(mobile) {
          flex-wrap: wrap;
          width: 90%;
          height: 150px;
          justify-content: space-between;
          align-items: center; }
        p {
          justify-content: space-between;
          display: flex;
          margin: 0 auto;
          align-items: center;
          @include mq(mobile) {
            flex: 0 1 33%;
            justify-content: center; } } } } } }
