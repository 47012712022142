#connect {
  background-image: url("/img/bg100.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: auto;
  color: $light-color;
  width: 100%;
  @include mq(mobile) {
    height: 100%; }
  .connected {
    &_title {
      font-weight: $extrabold;
      @include fontSize(50px);
      padding-top: 60px;
      padding-bottom: 70px;
      text-transform: uppercase;
      @include mq(mobile) {
        @include fontSize(38px);
        padding-bottom: 0px; } }
    &_box_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 908px;
      width: 100%;
      @media screen and (min-width: 1280px) {
        max-width: 1200px; }
      @include mq(mobile) {
        justify-content: center; } }
    &_box {
      border-top: 1px solid $light-color;
      color: $light-color;
      flex: 0 1 32%;
      min-height: 260px;
      padding-top: 20px;
      @include mq(tablet) {
        flex: 0 0 47.50%; }
      @include mq(mobile) {
        flex: 0 0 100%;
        padding-right: 0px;
        padding-top: 20px;
        min-height: 204px; }
      &_number {
        font-weight: 800;
        font-size: 6rem;
        text-shadow: 3px 1px 0 #EC6608;
        margin-bottom: 18px; }
      p {
        color: $light-color;
        font-weight: 300; }
      @include mq(mobile) {
        margin-bottom: 20px; } } } }
