.btn {
  width: 218px;
  height: 55px;
  background: url("/img/bg-button.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 6px 6px #43EFCE;
  border: 0px;
  @include mq(mobile) {
    width: 80%;
    margin: 0px auto; }
  &:hover {
    background: $dark-color;
    cursor: pointer; }
  &:focus {
    outline: none; }

  &_contact {
    min-width: 92px;
    min-height: 45px;
    color: $dark-color;
    text-transform: uppercase;
    background-color: $green;
    padding: 6px 10px;
    text-decoration: none;
    font-weight: 800;
    height: 100%;
    @include fontSize(14px);
    line-height: 1;
    box-shadow: 6px 6px $dark-color;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      box-shadow: 6px 6px #FFFFFF; }
    @include mq(tablet) {
      min-width: 120px; }
    &_text {
      text-decoration: none;
      color: $dark-color;
      font-weight: $semibold;
      font-family: Poppins; } } }

a.btn_txt {
  color: $light-color;
  text-transform: uppercase;
  background-color: $dark-color;
  padding: 6px 10px;
  text-decoration: none;
  font-weight: 800;
  @include fontSize(14px);
  line-height: 1;
  &--form {
    pointer-events: none; } }


button {
  .btn_text {
    color: $light-color;
    text-transform: uppercase;
    background-color: $dark-color;
    padding: 6px 10px;
    text-decoration: none;
    font-weight: 800;
    @include fontSize(14px);
    line-height: 1; } }

.btn-sticky {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 65%;
  right: 0;
  z-index: 2;
  width: 92px; }
