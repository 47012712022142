.modal {
  display: none;

  &.is-open {
    display: block;
    overflow-y: scroll; }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    background-color: rgba(#000, .9);
    z-index: z(overlay);
    overflow-y: auto;
    padding-top: 170px;
    // +mq(mobile)
 }    //   align-items: baseline

  &__container {
    max-width: 980px;
    width: 100%; }

  &__inner {
    position: relative; }

  &__close {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    outline: none;
    @include mq(tablet) {
      right: calc(50% - 20px); }
    img {
      pointer-events: none; }
    svg {
      width: 16px;
      height: 16px;
      pointer-events: none; } }

  &__embed {
    position: relative;
    padding-bottom: 56.25%; // 16/9
    height: 0;
    width: 100%;
    background-color: $dark-color; }

  &__zoom {
    width: 100%;
    height: auto;
    max-height: 90%;
    background: white;
    padding: 50px;
    box-shadow: 6px 6px $orange;
    @include mq(mobile) {
      height: auto;
      padding: 30px; }
    &__close {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 40px;
      height: 40px;
      background: $dark-color;
      border: 0px;
      cursor: pointer;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70% !important;
        pointer-events: none;
        position: relative !important; }
      svg {
        width: 16px;
        height: 16px;
        pointer-events: none; } }
    ul {
      flex-direction: column;
      align-items: flex-start; }
    ol, ul {
      list-style: disc; } }

  &__avail, &__avail-form {
    position: relative;
    width: 100%;
    max-width: 723px;
    height: auto;
    max-height: 90%;
    margin: auto;
    background: white;
    padding: 35px 41px;

    @include mq(mobile) {
      height: auto;
      padding: 40px 20px; }

    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 15px;
      height: 15px;
      border: 0;
      cursor: pointer;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center; }

    .modal__title {
      text-transform: uppercase;
      font-weight: 800; } }

  &#avail {
    .modal__container {
      height: 100vh; } }

  iframe, object, embed, video, img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

  // Animations

  &[aria-hidden="false"] .modal__overlay {
    animation: overlay-fade-in 200ms ease-out; }

  &[aria-hidden="false"] .modal__container {
    animation: modal-scale-up 200ms ease-out; }

  &[aria-hidden="true"] .modal__overlay {
    animation: overlay-fade-out 150ms ease-out; }

  &[aria-hidden="true"] .modal__container {
    animation: modal-scale-down 150ms ease-out; } }

@keyframes overlay-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes overlay-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes modal-scale-up {
  from {
    transform: scale(.85); }
  to {
    transform: none; } }

@keyframes modal-scale-down {
  from {
    transform: none; }
  to {
    transform: scale(1.1); } }

.modal_none {
  display: none; }
