.tournage {
  background-image: url('/img/Ruban.svg');
  background-repeat: no-repeat;
  background-position: calc(50% + 560px) -100px;
  background-size: 1500px;
  overflow: hidden;
  @include mq(mobile) {
    min-height: 1100px;
    height: 100%; } }
.tournages {
  margin-bottom: 60px;
  @include mq(mobile) {
    margin-bottom: 0; }
  .wrapper {
    padding-bottom: 50px;
    @include mq(mobile) {
      padding-bottom: 0; }
    .tournage {
      &_container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @include mq(mobile) {
          flex-wrap: wrap;
          margin-bottom: 50px; } }
      &_left,
      &_right {
        width: 49%;
        display: flex;
        flex-direction: column;
        @include mq(mobile) {
          width: 100%; } }

      &_left {
        padding-top: 60px; }

      &_right {
        padding-top: 135px;
        @include mq(mobile) {
            padding: 0px; } }
      &_text {
        display: block;
        margin-bottom: 70px; }
      &_img {
        position: relative;
        margin-bottom: 20px;
        @include mq(mobile) {
          width: 100%; }
        img {
            width: 100%; }
        &--align-right {
          align-self: flex-end;
          @include mq(mobile) {
            align-self: center; } }
        &_text {
          position: absolute;
          left: 20px;
          bottom: 20px;
          width: auto;
          height: 40px;
          @include mq(mobile) {
            height: 32px; } }
        & .orange_text {
          background: $orange;
          padding: 3px 5px 3px 3px;
          color: $light-color;
          margin-right: 5px;
          font-weight: $extrabold;
          @include mq(mobile) {
            font-weight: $black;
            padding: 6px 8px 6px 5px; } } } }
    .plus {
        background-color: $black-color;
        color: $light-color;
        padding: 3px 10px;
        font-weight: $semibold;
        cursor: pointer; } } }
