#studio-video {
  background-image: url(/img/podium.png);
  background-color: $orange;
  background-repeat: no-repeat;
  background-position: right -1015px top -300px;
  background-size: 1700px;
  width: 100%;
  max-width: 100vw;
  min-height: 800px;
  margin-top: -100px;
  height: calc(100vh + 100px);
  overflow: hidden;
  @include mq(tablet) {
    background-position: right -690px top -180px;
    background-size: 1000px; }
  @include mq(mobile) {
    background-position: right -770px top -200px;
    background-color: $orange;
    min-height: inherit; }

  .wrapper {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: flex-end; }

  .podium__block {
    padding-left: 50px;
    margin-bottom: 100px;
    width: 75%;
    @include mq(tablet) {
      margin-bottom: 50px; }
    @include mq(mobile) {
      padding-left: 10px;
      margin-bottom: 30px;
      width: 100%; }
    h1 {
      @include mq(mobile) {
        padding-right: 50px; } } }

  h3.podium__title {
    margin: 35px 0px 100px 0px;
    display: block;
    @include mq(tablet) {
      margin-bottom: 50px; }
    @include mq(mobile) {
      // margin-bottom: 0
      min-height: 60px;
      max-width: 260px; }
    &::before {
      content: '';
      background: url("/img/Glyph.svg") no-repeat;
      display: block;
      width: 80px;
      height: 70px;
      position: absolute;
      left: -85px;
      top: -20px;
      @include mq(mobile) {
        width: 44px;
        left: 0px;
        background-size: cover;
        height: 40px;
        top: -5px; } }
    .green-bg {
      display: inline-block; } }

  .button-podium {
    display: flex;
    @include mq(mobile) {
      flex-direction: column;
      justify-content: center; }
    button:first-child {
      margin-right: 30px;
      @include mq(mobile) {
        margin: 0 auto 30px; } }
    button:last-child {
      width: 260px;
      @include mq(mobile) {
        width: 80%; } } } }

.digitalEvent {
  #studio-video {
    background-image: none;
    color: #ffffff;

    &:before {
      content: "";
      position: absolute;
      top: -280px;
      left: -280px;
      height: 460px;
      width: 710px;
      transform: rotate(270deg);
      background-image: url(/img/podium.png);
      background-repeat: no-repeat;
      background-size: contain;
      //+mq(tablet)
      //  background-position: right -690px top -180px
      //  background-size: 1000px
      //+mq(mobile)
 }      //  background-position: right -770px top -200px

    .wrapper {
      justify-content: flex-start;
      align-items: center; }
    .podium {
      &__title {
        margin-bottom: 50px;
        color: #ffffff;
        text-align: center;
        @include mq(tablet) {
          padding-right: 0; } }

      &__block {
        margin-top: 260px;
        z-index: 1;
        padding: 0; }

      &__list {
        display: flex;
        flex-flow: wrap;
        align-items: flex-start;
        @include mq(tablet) {
          flex-direction: column; }

        li {
          flex-basis: 50%;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 1.4;
          padding-bottom: 30px; } } } } }
