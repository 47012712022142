#footer {
  border-top: 1px solid #D8D8D8;
  height: 210px;
  .wrapper {
    @include mq(tablet) {
      max-width: 965px; } }
  .container {
    display: flex;
    justify-content: space-between;
    margin: 70px 0px;
    font-weight: $regular;
    padding: 0px;
    width: 100%;
    @include mq(mobile) {
      flex-wrap: wrap;
      margin: 0px;
      justify-content: left;
      margin-bottom: 50px; }
    .telephone {
      @include fontSize(16px);
      a {
        color: #000000;
        text-decoration: none; } }
    p {
      @include fontSize(12px);
      a {
        text-decoration: none;
        color: $black-color; } } }

  .studio {
    span {
      font-weight: $extrabold; } }
  .box {
    border-left: 1px solid #d8d8d8;
    max-width: 260px;
    height: 142px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 25%;
    @include mq(tablet) {
      border: none;
      align-items: flex-start;
      padding: 0px 20px;
      flex: 0 1 25%; }
    @include mq(mobile) {
      border: none;
      align-items: flex-start;
      padding: 0px 20px;
      flex: 0 1 100%; }
    img {
      width: 150px;
      height: 56px; } }
  .logo {
      align-items: center; }

  .reseau {
    border-right: 1px solid #d8d8d8;
    display: flex;
    @include mq(tablet) {
      border-right: 0px; }
    @include mq(tablet) {
      border: none;
      justify-content: left; } }
  .social-media {
    display: flex;
    align-items: center;
    line-height: 1;
    height: 40px;
    justify-content: space-between;
    width: 75%;
    &_image-wrapper {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        height: 20px;
        width: 20px;
        margin-right: 5px; }
      a {
        font-size: 1.2rem;
        color: $black-color;
        text-decoration: none;
        min-width: 95px;
        display: flex;
        align-items: center; } } } }
