#flyer {
  background-image: url(/img/bordure-flyer.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  min-height: inherit;
  padding: 15px 15px 0 15px;
  z-index: 1;
  position: relative;
  margin-bottom: 45px;
  &::before {
    content: '';
    background-image: url(/img/Groupe10.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 57%;
    right: 5%;
    position: absolute;
    z-index: 5;
    top: 0;
    @include mq(mobile) {
      right: 0;
      width: 50%; } }

  .container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #ffffff;
    height: 100%;
    height: 320px;
    padding: 0;
    margin: 0;

    .wrapper {
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      height: 310px;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      @include mq(mobile) {
        height: 85%; }

      .btn {
        z-index: 5; } } } }

@media screen and (max-width: 980px) {
  #flyer .container .wrapper {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; } }

@media screen and (max-width: 414px) {
  #flyer {
    &::before {
      width: 100%; } } }
