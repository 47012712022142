.digitalEvent {
  font-family: $body-font;

  h2 {
    margin-bottom: 30px;
    font-size: 35px;
    line-height: 45px;
    font-family: Poppins;
    @include mq(tablet) {
      font-size: 25px;
      line-height: 30px; } }

  h3 {
    text-transform: uppercase; }

  strong {
    font-weight: 700; }

  section {
    min-height: auto;
    a {
      color: $orange;
      text-decoration: underline; } }

  .btn {
    padding: 20px 30px;
    height: 85px;
    width: auto;
    @include mq(tablet) {
      padding: 15px 25px;
      height: auto; }
    a {
      text-decoration: none;
      color: $light-color;
      font-size: 22px;
      @include mq(tablet) {
        font-size: 15px; } } }

  .wrapper {
    max-width: 1000px; }

  p, li {
    font-weight: 400;
    @include mq(tablet) {
      font-size: 12px;
      line-height: 16px; } }

  .green_color {
    padding: 0 10px; }


  #studio-video {
    &.event-digital {
      background-color: #fe5b00;
      @include mq(tablet) {
        height: 100%; }
      .podium {
        &__title {
          @include mq(tablet) {
            margin-bottom: 15px; } }
        &__block {
          width: 100%;
          color: $light-color;
          p {
            color: $light-color;
            font-size: 18px;
            line-height: 24px;
            @include mq(tablet) {
              font-size: 12px;
              line-height: 16px; } } }

        &__list {
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 300px;
          justify-content: flex-start;
          align-items: center;
          margin-right: 0;
          @include mq(tablet) {
            max-height: 100%; }
          .ancre-digital {
            width: 100%;
            max-width: 430px;
            display: flex;
            color: #fff;
            text-decoration: none;
            width: 50%;
            @include mq(tablet) {
              width: 100%; } }
          li {
            // width: 100%
            // max-width: 430px
            // display: flex
            flex-basis: 100%;


            &:nth-child(n+5) {
              margin-left: auto; }

            span {
              display: inline-flex;
              padding-left: 15px; } } } } } }

  .content1 {
    padding-top: 65px;
    @include mq(tablet) {
      margin-top: 15px; }
    .content {
      &-text {
        width: 50%;
        @include mq(tablet) {
          width: 100%;
          z-index: 1;
          padding-right: 10px; }
        p {
          font-size: 16px;
          line-height: 1.5; } }


      &__images {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          background-image: url('/img/digitalEvent/groupe-orange.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: top right;
          width: 100%;
          height: 140%;
          top: -15%;
          @include mq(tablet) {
            top: -80%;
            height: 235%;
            background-image: url('/img/digitalEvent/groupe-orange-mobile.png');
            background-size: 100% 100%; } }

        @include mq(tablet) {
          width: 90%;
          margin-left: auto;
          margin-top: 25px; } }

      &-img_1,
      &-img_2 {
        width: 100%;
        img {
          object-fit: cover; } }

      &-img_1 {
        display: flex;
        justify-content: flex-end;
        img {
          height: 320px;
          transform: translateY(-45px);
          width: 80%;
          @include mq(tablet) {
            height: 210px;
            transform: translateY(0); } } }

      &-img_2 {
        img {
          width: 100%;
          height: 395px;
          transform: translateY(0);

          @include mq(tablet) {
            height: 255px;
            transform: translateY(35px); } } }

      &__button {
        padding-top: 45px;
        padding-bottom: 45px;
        width: 90%;
        .btn {
          margin: 0 auto; }
        @include mq(mobile) {
          width: 100%; } } }

    .wrapper {
      flex-direction: inherit;
      justify-content: space-between;
      margin-right: 0;
      max-width: 1200px;
      @include mq(tablet) {
        flex-direction: column; } } }


  .bonne-pratique {
    // background-color: $dark-color
    width: 100%;
    height: 100%;
    background-image: url('/img/digitalEvent/background-interviews.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #000000;
    background-size: 100%;
    margin-top: 0;
    color: $light-color;
    padding: 40px 0;
    margin-top: 0;
    position: relative;
    padding-bottom: 80px;
    @include mq(tablet) {
      background-size: cover;
      background-image: url('/img/digitalEvent/mobile-background-bonne-pratique_02_02.png'); }
    &::before {
      content: '';
      background-image: url('/img/digitalEvent/bg-vert-rs.png');
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      width: 20%;
      position: absolute;
      bottom: -65%;
      @include mq(tablet) {
        background-size: 100%;
        bottom: -96%; } }

    .content {
      justify-content: center;
      &__text {
        text-align: center;
        line-height: 24px;
        color: $light-color;

        @include mq(tablet) {
          line-height: 16px;
          font-size: 12px;
          margin-top: 30px; }
        h2 {
          @include mq(tablet) {
            text-align: center; } } }
      &__list {
        padding-top: 0;
        li {
          width: 40%;
          margin-top: 60px;
          p {
            color: $light-color; } } }
      &__quote {
        .text {
          padding: 80px 0; } } } }

  .content2 {
    // margin-top: 40px
    @include mq(tablet) {
      margin-top: 230px; }
    .content {
      justify-content: space-between;
      margin-top: 70px;
      & > div {
        flex-basis: 50%; }
      &__text {
        text-align: right;
        z-index: 1;
        @include mq(tablet) {
          margin-top: 40px;
          text-align: left; }
        h2 {
          @include mq(tablet) {
            width: 80%; } }
        p {
          font-size: 16px; } }
      &__list {
        padding-top: 75px;
        width: 95%;
        li {
          width: 40%;
          padding-bottom: 100px;
          @include mq(tablet) {
            padding-bottom: 60px; } } }
      &__quote {
        margin-left: -70px;
        @include mq(tablet) {
          margin: 0 auto; }
        .text {
          padding: 110px 0; } } } }

  .content2,
  .bonne-pratique {
    .content {
      display: flex;
      @include mq(tablet) {
        flex-direction: column; }
      &__quote {
        width: 48%;
        background-image: url('/img/digitalEvent/cercle.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: -1;
        @include mq(tablet) {
          width: 100%;
          height: auto;
          background-size: contain; }
        &::before,
        &::after {
          content: '';
          background-image: url('/img/digitalEvent/guillemets.png');
          width: 73px;
          height: 62px;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 1;
          @include mq(tablet) {
            width: 50px;
            height: 45px;
            background-size: contain; } }
        &::before {
          top: 10px;
          left: 50px;
          @include mq(tablet) {
            top: -10px; } }
        &::after {
          transform: rotate(180deg);
          bottom: 0;
          right: 70px;
          @include mq(tablet) {
            bottom: -10px; } }
        .text {
          font-size: 40px;
          width: 60%;
          margin: 0 auto;
          text-align: center;
          line-height: 48px;
          font-weight: bold;
          @include mq(tablet) {
            font-size: 28px;
            padding: 60px 0;
            line-height: 33px;
            width: 70%; }
          span {
            font-size: 80px;
            line-height: 48px;
            display: block;
            padding: 10px 0;
            @include mq(tablet) {
              font-size: 56px;
              line-height: 33px; } } } }

      &__text {
        align-self: flex-end; }

      &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 80px;
        align-items: flex-start;
        @include mq(tablet) {
          flex-direction: column;
          padding-left: 60px; }
        li {
          position: relative;

          @include mq(tablet) {
            width: 100%; }
          &::before {
            content: '';
            position: absolute;
            background-image: url('/img/digitalEvent/fleche.png');
            background-repeat: no-repeat;
            height: 48px;
            width: 60px;
            left: -80px;
            top: -10px;
            @include mq(tablet) {
              width: 43px;
              height: 35px;
              background-size: contain;
              left: -60px; } }
          h3 {
            font-size: 25px;
            padding-bottom: 30px;
            @include mq(tablet) {
              font-size: 17px;
              padding-bottom: 20px; } }
          p {
            font-size: 16px;
            line-height: 1.4; } } } } }


  .module2 {
    background-color: $black-color;
    display: flex;
    padding: 100px 0;
    color: $light-color;
    font-family: Poppins;
    position: relative;
    background-image: url('/img/digitalEvent/background-interviews.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @include mq(tablet) {
      background-image: none; }
    &::before {
      content: '';
      position: absolute;
      background-image: url('/img/digitalEvent/background-direct.png');
      background-repeat: no-repeat;
      z-index: 1;
      background-position: top left;
      height: 100%;
      width: 20%;
      top: -30%;
      @include mq(tablet) {
        top: -2%;
        background-size: 100%; } }

    p {
      color: $light-color; }
    .wrapper {
      margin-right: 0;
      max-width: 1200px;
      @include mq(tablet) {
        margin: 0 auto; } }
    .content {
      &__top {
        display: flex;
        justify-content: space-between;
        &__left {
          width: 45%;
          @include mq(tablet) {
            width: 100%; } } }
      &__image {
        width: 45%;
        @include mq(tablet) {
          display: none; }
        img {
          width: 100%;
          max-height: 550px;
          height: 100%;
          object-fit: cover; } }
      &__title {
        text-transform: uppercase;
        font-weight: bold;
        width: 50%;
        line-height: 1.4;
        @include mq(tablet) {
          width: 100%; } }
      &__text {
        padding-top: 20px;
        font-weight: normal;
        @include mq(tablet);
        padding-top: 0;
        p {
          font-size: 16px;
          line-height: 22px; }
        &.text2 {
          width: 85%;
          padding-top: 40px;
          @include mq(tablet) {
            margin: 0 auto;
            width: 100%; } } }
      &__button {
        justify-content: center;
        display: flex;
        padding-top: 75px;
        .btn {
          // padding: 25px 30px
          width: auto; } } } }

  .conception {
    padding: 75px 0;
    @include mq(tablet) {
      padding: 40px 0; }
    .content {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      @include mq(tablet) {
        width: 100%; } }

    .text {
      line-height: 1.4;
      font-weight: normal;
      padding-top: 40px;
      @include mq(tablet) {
        font-size: 12px;
        padding-top: 0; } } }

  .decouvrez-les-plateaux {
    .content {
      &__title {
        background-image: url('/img/digitalEvent/background-vert.png');
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 35px 0;
        @include mq(tablet) {
          background-size: cover; }
        h2 {
          margin-bottom: 0;
          @include mq(tablet) {
            width: 80%; } }
        .button {
          @include mq(tablet) {
            margin-top: 40px; } }

        .wrapper {
          flex-direction: inherit;
          justify-content: space-between;
          @include mq(tablet) {
            flex-direction: column; }
          .outline,
          .button {
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mq(tablet) {
              width: 100%;
              text-align: center; } } } }

      &__blocks {
        display: flex;
        flex-direction: column;

        &__information {
          width: 50%;
          justify-content: flex-start;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding: 35px;
          @include mq(tablet) {
            width: 100%;
            justify-content: center; }


          &.information2 {
            align-items: flex-end;
            @include mq(tablet) {
              order: 2; }

            .content {
              &__block {
                &__information {
                  justify-content: flex-end;
                  @include mq(tablet) {
                    justify-content: center; }
                  .information {
                    &__block {
                      width: 45%;
                      padding: 0 10px;
                      @include mq(tablet) {
                        width: 50%; } } } } } }

            .information {
              justify-content: flex-end;
              &__block {
                justify-content: flex-end; } } }
          h2 {
            margin-bottom: 0; } }



        .information {
          &__title {
            width: 80%;
            @include mq(tablet) {
              justify-content: center;
              display: flex;
              margin: 0 auto 30px; }
            h2 {
              display: inline-block;
              font-weight: bold;
              .green_color {
                padding: 5px; }
              @include mq(tablet) {
                display: ruby; } } }

          &__datas {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .background {
              background-repeat: no-repeat;
              background-position: bottom;
              height: 100%;
              width: 100%;
              margin-top: 10px;
              &.picto1 {
                background-image: url('/img/digitalEvent/carreaux.png');
                background-size: 120px 75px;
                @include mq(tablet) {
                  background-size: 84px 52px; } }
              &.picto2 {
                background-image: url('/img/digitalEvent/people.png');
                background-size: 62px 76px;
                @include mq(tablet) {
                  background-size: 44px 54px; } } }

            .data-chiffre {
              font-size: 52px;
              font-weight: bold;
              padding-bottom: 80px;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              @include mq(tablet) {
                font-size: 36px;
                padding-bottom: 70px; }
              span {
                font-size: 26px;
                padding-bottom: 5px; } }
            .data-text {
              text-align: center;
              font-size: 20px;
              line-height: 1.3;
              font-weight: 500;
              @include mq(tablet) {
                font-size: 12px;
                line-height: 16px; } } } } }
      &__block {
        display: flex;

        @include mq(tablet) {
          flex-direction: column; }
        &__image {
          width: 50%;
          @include mq(tablet) {
            width: 100%; }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include mq(tablet) {
              height: 330px; } } }
        &__information {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          padding: 35px;
          @include mq(tablet) {
            padding: 0;
            width: 100%;
            justify-content: center; }

          .data {
            font-size: 19px;
            font-weight: medium; }

          .information {
            &__block {
              width: 45%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-image: url('/img/digitalEvent/cercle-gris.png');
              background-repeat: no-repeat;
              background-size: 50%;
              background-position: center;
              @include mq(tablet) {
                width: 50%; } } } }

        .information-studio {
          width: 85%;
          padding: 35px 0 35px 0;
          align-items: center;
          @include mq(tablet) {
            width: 100%;
            padding: 0 0 35px 0; }
          .information {
            &__block {
              background-position: center; } }
          .content-left {
            padding-top: 30px;
            @include mq(tablet) {
              flex-direction: column;
              align-items: center; }
            .data-sign {
              display: flex;
              align-items: center;
              font-size: 29px; }

            .information {
              &__block {
                background-size: 100%;
                background-position: top left;
                padding: 0 0;
                @include mq(tablet) {
                  background-size: 70%;
                  background-position: top center; } } }

            .picto1 {
              background-size: contain; } }
          .picto2 {
            .data-chiffre {
              flex-direction: inherit !important; } }
          .data-text {
            text-align: center;
            font-size: 20px;
            line-height: 1.3;
            font-weight: 500;
            @include mq(tablet) {
              font-size: 12px; } }
          .data-chiffre {
            flex-direction: column;
            span {
              display: block; }
            .desc {
              text-align: center;
              font-size: 20px;
              line-height: 1.3;
              font-weight: 500;
              @include mq(tablet) {
                font-size: 12px; } }
            .chiffre-top {
              display: flex;
              align-items: flex-end; } }
          .block-left {
            display: flex;
            flex-direction: column;
            width: 65%;
            @include mq(tablet) {
              width: 50%; }
            .data-text {
              margin: 0 auto; }
            .content-left {
              display: flex;
              justify-content: space-between; }
            .information__block {
              width: 45% !important;
              justify-content: center;
              @include mq(tablet) {
                width: 100% !important; }
              .chiffre-sub {
                font-size: 26px; }
              .data-chiffre {
                align-items: flex-start;
                @include mq(tablet) {
                  align-items: center; } } }
            .background {
              &.picto3 {
                background-image: url('/img/digitalEvent/picto3.png');
                background-size: 122px 76px;
                @include mq(tablet) {
                  background-size: 84px 52px; } } } }
          .last-block {
            background-size: 60%;
            background-position: top 40% center;
            @include mq(tablet) {
              display: flex;
              align-items: flex-start;
              background-size: 70%;
              background-position: top 29% center; } } } } } }


  .studio-plateaux {
    padding: 75px 0;
    margin: auto;

    @include mq(tablet) {
      margin: 0 auto 50px;
      padding: 0 0 75px; }
    .module4 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include mq(tablet) {
        text-align: left; }
      .btn {
        margin-top: 75px;
        @include mq(tablet) {
          width: 100%; } }
      .content {
        &__images {
          display: flex;
          justify-content: space-between;
          padding-top: 75px;
          width: 100%;
          @include mq(tablet) {
            flex-direction: column; } }

        &__image {
          width: 48%;
          @include mq(tablet) {
            width: 100%; }
          &:last-child {
            @include mq(tablet) {
              margin-top: 30px; } }
          img {
            width: 100%; } } } } }

  .interview-block {
    background-color: $orange;
    padding: 25px 0;
    color: $light-color;
    z-index: -1;
    position: relative;
    .wrapper {
      max-width: 1200px;
      margin-right: 0;
      @include mq(tablet) {
        margin: 0 auto; } }
    .interview {
      &__content {
        &__title {
          h2 {
            font-size: 50px;
            line-height: 60px;
            @include mq(tablet) {
              font-size: 35px;
              line-height: 42px; } }
          .outline {
            color: transparent;
            -webkit-text-stroke: 1px;
            -webkit-text-stroke-color: $light-color;
            text-transform: uppercase;
            line-height: 1.2;
            margin-left: 0;
            display: block; } } } }

    p {
      color: $light-color; }
    .interview {
      display: flex;
      justify-content: space-between;

      @include mq(tablet) {
        flex-direction: column; }
      &__content {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 35px;
        @include mq(tablet) {
          width: 100%; } }

      &__image {
        width: 35%;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          background-image: url('/img/digitalEvent/background-interview.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          left: -60px;
          height: 100%;
          width: 100%;
          z-index: -1; }
        @include mq(tablet) {
          width: 100%;
          margin-top: 30px; }
        .img {
          padding-bottom: 100%;
          width: 100%;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: right; } } } }

  .accordeon {
    padding: 45px 0;
    .accordion {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
      @include mq(tablet) {
        width: 100%; }
      li {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        min-height: 40px;
        width: 100%;
        flex-direction: column;
        border: 4px solid #f6f7f7; }

      &-toggle {
        border: 0;
        text-align: left;
        font-family: Poppins;
        width: 100%;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          background-image: url('/img/digitalEvent/noun_Close.svg');
          background-repeat: no-repeat;
          background-size: contain;
          height: 34px;
          width: 34px;
          right: 35px;
          top: 35px;
          transform: rotate(45deg);
          @include mq(tablet) {
            width: 21px;
            height: 21px;
            background-size: 21px 21px;
            top: 10px;
            right: 10px; } }
        &:not(.active) {
          background-color: #f6f7f7; }
        &.active {
          &::after {
            transform: none; } } }


      &-panel {
        display: flex;
        padding: 0 50px 40px 30px;
        flex-direction: column;
        align-items: center;
        @include mq(tablet) {
          flex-direction: column;
          padding: 0 15px 40px 15px; } }

      &-subtitle {
        display: block;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        background-color: #ffffff;
        margin: 0;
        padding: 40px 50px; }

      &-text {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        padding: 30px 0;
        @include mq(tablet) {
          width: 100%;
          margin: 0;
          padding: 0 !important;
          border: 0 !important;
          padding: 30px 15px; } }


      &-text {
        font-family: Poppins;
        width: 80%;
        padding-right: 10px;
        @include mq(tablet) {
          margin-bottom: 20px; }
        &.question {
          color: $orange;
          font-weight: bold;
          font-size: 20px;
          line-height: 1.3;
          margin-left: 30px;
          @include mq(tablet) {
            font-size: 14px;
            margin-left: 0;
            margin: 40px 15px 20px; } }
        &.reponse {
          font-weight: normal;
          padding-top: 0;
          font-size: 16px;
          line-height: 1.3;
          margin-left: 0;
          width: 90%;
          padding-bottom: 0;
          @include mq(tablet) {
            font-size: 12px;
            line-height: 16px; } } } } }


  .fonctionnement-rs {
    padding-bottom: 45px;
    .module {
      &__content {
        padding: 75px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__title {
          font-size: 35px;
          text-transform: uppercase;
          text-align: center;
          font-weight: bold;
          width: 80%;
          margin: 0 auto;
          @include mq(tablet) {
            font-size: 25px;
            text-align: center;
            width: 100%; } }
        &__text {
          text-align: center;
          font-size: 16px;
          padding-bottom: 65px;
          font-weight: bold;
          width: 80%;
          margin: 0 auto;
          padding-top: 50px;
          line-height: 22px;
          @include mq(tablet) {
            width: 100%;
            padding-top: 25px;
            padding-bottom: 40px; }

          &.text2 {
            width: 80%;
            font-weight: 400;
            padding-top: 0;
            @media screen and (min-width: 1024px) {
              padding-bottom: 0; }
            @include mq(tablet) {
              width: 100%;
              text-align: left;
              padding-bottom: 0; } } }

        &__datas {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-bottom: 90px;
          padding-top: 65px;
          @include mq(tablet) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 30px;
            padding-bottom: 30px; }
          .outline {
            color: transparent;
            -webkit-text-stroke: 1px;
            -webkit-text-stroke-color: $dark-color;
            text-transform: uppercase;
            line-height: 1.2;
            margin-left: 0; }
          .data {
            width: 30%;
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            align-items: flex-start;
            position: relative;

            &::before {
              content: '';
              background-image: url('/img/digitalEvent/cercle-vert_03.png');
              background-repeat: no-repeat;
              background-size: contain;
              position: absolute;
              height: 130px;
              width: 145px;
              z-index: -1;
              background-position: top left;
              @include mq(tablet) {
                width: 100px;
                height: 90px;
                background-position: top left;
                background-size: 100%;
                top: 0;
                left: 0; } }

            @include mq(tablet) {
              width: 70%;
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              margin-bottom: 25px;
              padding-left: 0;
              justify-content: center;
              align-items: center; }
            &__chiffre {
              font-size: 100px;
              line-height: 100px;
              display: flex;
              padding-left: 25px;
              padding-top: 15px;
              @include mq(tablet) {
                line-height: 83px;
                font-size: 70px;
                padding-top: 0;
                padding-left: 0; }

              .content {
                &-data1,
                &-data3 {
                  font-size: 54px;
                  display: flex;
                  @include mq(tablet) {
                    font-size: 37px; } }

                &-data1 {
                  align-items: flex-end;
                  justify-content: flex-end;
                  line-height: 1.1;
                  @include mq(tablet) {
                    line-height: 1.4; } }

                &-data3 {
                  align-items: flex-start;
                  justify-content: flex-start;
                  line-height: 1.3; } }


              &.last-datas {
                display: flex;
                align-items: flex-start;
                padding-left: 0;
                .last-data {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: center; }

                .content {
                  &-data1 {
                    font-size: 100px;
                    padding-bottom: 0;
                    line-height: 90px;
                    @include mq(tablet) {
                      font-size: 70px;
                      line-height: 50px; } }

                  &-data2 {
                    font-size: 17px;
                    line-height: 22px;
                    @include mq(tablet); }


                  &-data3 {
                    display: flex;
                    font-size: 80px;
                    font-weight: 100;
                    @include mq(tablet) {
                      font-size: 50px; } }

                  &-data4 {
                    font-size: 100px;
                    @include mq(tablet) {
                      font-size: 70px; } } } } }



            &__text {
              font-weight: bold;
              text-align: left;
              width: 65%;
              @include mq(tablet) {
                width: 80%; } } } }
        &__button {
          padding-top: 280px;
          width: 100%; } } } }

  .retenir {
    background-color: $orange;
    position: relative;
    &::before {
      content: '';
      background-image: url('/img/digitalEvent/bg-retenir.png');
      background-repeat: no-repeat;
      background-position: top right;
      position: absolute;
      width: 30%;
      height: 110%;
      top: -25%;
      right: 0;
      background-size: 100% 100%;
      @include mq(tablet) {
        display: none; } }
    .module {
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: left;
        color: $light-color;
        padding: 50px 0 75px;
        width: 65%;
        @include mq(tablet) {
          width: 100%; }
        &__title {
          text-align: left;
          text-transform: uppercase;
          font-weight: bold;
          width: 80%;

          h2 {
            font-size: 51px;
            line-height: 62px;
            @include mq(tablet) {
              font-size: 25px;
              line-height: 30px; } } }

        &__text {
          font-size: 18px;
          text-align: left;
          line-height: 26px;
          @include mq(tablet) {
            padding-top: 10px; }
          p {
            color: $light-color;
            line-height: 26px;
            font-size: 18px;
            @include mq(tablet) {
              font-size: 12px;
              line-height: 16px; } } } } } }

  .contact {
    background-image: url('/img/digitalEvent/background-devis.png');
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(tablet) {
      background-size: cover; }
    .devis {
      &__content {
        display: flex;
        padding: 80px 0;
        justify-content: space-between;
        font-size: 50px;
        text-transform: uppercase;
        @include mq(tablet) {
          flex-direction: column;
          text-align: center;
          font-size: 35px; }
        &__text {
          font-weight: bold; }
        &__contact {
          color: $light-color;
          -webkit-text-stroke: 1px;
          -webkit-text-stroke-color: $dark-color;
          line-height: 1.2;
          margin-left: 5px;
          @include mq(tablet) {
            padding-top: 40px;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center; } }
        &__phone {
          display: flex; }
        a {
          text-decoration: none;
          color: $dark-color;
          font-size: 50px;
          @include mq(tablet) {
            font-size: 35px; }
          &.phone {
            color: $light-color;
            -webkit-text-stroke: 1px;
            -webkit-text-stroke-color: $dark-color;
            line-height: 1;
            margin-left: -10px; } } } } }

  .ancre-digitalEvent {
    position: fixed;
    left: 0;
    z-index: 100;
    top: 50%;
    &.hidden {
      opacity: 0; }
    // &.standard
    //   a.active
    //     color: $orange
    //     &::before
    //       border-top: 2px solid $orange
    // &.alternate
    //   a
    //     color: $light-color
    //     &::before
    //       border-top: 2px solid $light-color
    //   a.active
    //     color: #81eccf
    //     &::before
    //       border-top: 2px solid #81eccf

    @include mq(tablet) {
      display: none; }

    nav {
      max-width: 110px;
      width: 100%;
      &.hidden {
        opacity: 0 !important; } }

    .item-nav {

      &.standard a {
        color: #8a8e8d !important;

        &::before {
          border-color: #8a8e8d !important; } }

      &.alternate a {
        color: white !important;

        &::before {
          border-color: white !important; } }

      &.standard {
        &.active {
          a {
            color: #fe5b00 !important;
            &::before {
              border-color: #fe5b00 !important; } } } }

      &.alternate {
        &.active {
          a {
            color: #81eccf !important;

            &::before {
              border-color: #81eccf !important; } } } } }

    ul {
      list-style: none;
      flex-direction: column;
      width: 100%;
      li {
        margin-bottom: 20px;
        margin-left: 0;
        position: relative;

        .number {
          position: absolute;
          top: -20px;
          font-size: 20px;
          right: 0;
          font-weight: bold; }
        a {
          font-family: Poppins;
          line-height: 1.2;
          font-weight: 700;
          display: flex;
          color: #8a8e8d;
          text-decoration: none;
          display: flex;
          align-items: center;
          font-size: 12px;
          margin-left: 25px;
          position: relative;
          &:before {
            content: '';
            margin-right: 10px;
            border-top: 2px solid #8a8e8d;
            position: absolute;
            width: 80%;
            top: -6px;
            left: -25px; } } } } }

  .download-flyer-block {
    margin-top: 0;
    position: relative;
    padding-bottom: 50px;
    min-height: 400px;
    display: flex;
    align-items: flex-end;
    @media screen and (min-width: 1024px) {
      min-height: 270px; }
    @include mq(mobile) {
      min-height: 270px; }
    &::before {
      content: '';
      position: absolute;
      background-image: url('/img/digitalEvent/Groupe11.png');
      background-repeat: no-repeat;
      background-position: bottom right 10%;
      z-index: 9;
      background-size: contain;
      height: 100%;
      width: 50%;
      right: 5%;
      @include mq(tablet) {
        background-position: center center;
        width: 90%;
        right: 0;
        top: -70px;
        z-index: 0; } }
    .wrapper {
      margin: auto;
      @include mq(tablet) {
        margin: 0 auto; } }
    .module {
      &__content {
        &__button {
          @include mq(tablet) {
            z-index: 1; } } } } } }
.btn-sticky {
  &.eventdigital {
    @include mq(tablet) {
      display: none; } } }
