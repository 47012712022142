.header {
  position: fixed;
  min-height: 100px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  height: 100px;
  @include mq(mobile) {
    min-height: 65px;
    height: 65px;
    padding: 8px 0px 9px 20px;
    flex-direction: column;
    align-items: flex-start; }
  .logo {
    display: block;
    transition: all 0.3s ease;
    @include mq(mobile) {
      height: 48px; } }
  .logo-sticky {
    display: none;
    transition: all 0.3s ease;
    @include mq(mobile) {
      height: 48px; } }

  &.mobile-active {
    background: $dark-color;
    height: 100vh;
    .logo {
      display: none;
      transition: all 0.3s ease;
      @include mq(mobile) {
        height: 48px; } }
    .logo-sticky {
      display: block;
      transition: all 0.3s ease;
      @include mq(mobile) {
        height: 48px; } } }

  &.sticky {
    background: $dark-color;
    transition: all 0.1s ease-in-out;
    .logo {
      display: none;
      transition: all 0.3s ease;
      @include mq(mobile) {
        height: 48px; } }
    .logo-sticky {
      display: block;
      transition: all 0.3s ease;
      @include mq(mobile) {
        height: 48px; } }
    .scroll {
      &_none {
        display: none; }
      &_flex {
        display: flex;
        min-width: 92px;
        min-height: 45px;
        color: #1D1B1B;
        text-transform: uppercase;
        background-color: #FFFFFF;
        padding: 6px 10px;
        text-decoration: none;
        font-weight: 800;
        font-size: 1.4rem;
        line-height: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        &:hover {
          -webkit-box-shadow: 6px 6px #43EFCE;
          box-shadow: 6px 6px #43EFCE; }
        .btn_contact_text {
          a {
            color: #000000; } } } } }


  &.always-sticky {
    background: $dark-color;
    .logo {
      display: none; }
    .logo-sticky {
      display: block; } }

  &.dark {
    background: $dark-color;
    .logo {
      display: none; }
    .logo-sticky {
      display: block; } } }

.scroll {
  &_flex {
    display: none; } }
nav, ul {
  display: flex;
  align-items: center;
  margin-right: 20px; }

nav {
  @include mq(mobile) {
    height: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden; }
  &.mobile-active {
    height: 100vh;
    transition: all 0.3s ease-in-out; }
  ul {
    @include mq(mobile) {
      margin-top: 100px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; } }

  li {
    margin-left: 20px;
    color: $light-color;
    text-transform: uppercase;
    @include fontSize(14px);
    font-weight: 300;
    font-family: Poppins;
    @include mq(mobile) {
      margin-top: 50px;
      @include fontSize(18px); }
    a {
      color: $light-color;
      text-transform: uppercase;
      @include fontSize(14px);
      font-weight: $bold;
      text-decoration: none;
      @include mq(mobile) {
        @include fontSize(18px); } }
    button {
      background-color: $green;
      width: 99px;
      height: 52px;
      padding: 15px 10px;
      border-color: transparent;
      box-shadow: 8px 8px; } } }

#menu-trigger {
  display: none;
  @include mq(mobile) {
    display: block;
    background-image: url("/img/burger.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 26px;
    height: 20px;
    position: absolute;
    top: 22px;
    right: 20px;
    z-index: 50;
    cursor: pointer; } }
