@function parseInt($n) {
  @return $n / ($n * 0 + 1); }

@mixin rem($property, $values) {
	$px : ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px : append($px , $value);
			$rem: append($rem, $value); }

		@else {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == "px" {
				$px : append($px,  $value);
				$rem: append($rem, ($val / 10 + rem)); }

			@if $unit == "rem" {
				$px : append($px, ($val * 10 + px));
				$rem: append($rem, $value); } } }

	@if $px == $rem {
		#{$property}: $px; }
	@else {
		#{$property}: $rem; } }

@mixin fontSize($value) {
	@include rem(font-size, $value); }

@mixin mq($size) {
	@if map-has-key($breakpoints, $size) {
		$breakpoint: map-get($breakpoints, $size);
		@media #{$breakpoint} {
			@content; } }
	@else {
		$breakpoint: $size;
		@media #{$breakpoint} {
			@content; } } }

@function z($layer) {
  @if not map-has-key($z-indexes, $layer) {
    @error 'There is no `#{$layer}` key in the $z-indexes map'; }

  @return map-get($z-indexes, $layer); }
