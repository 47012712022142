@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Thin.woff2') format("woff2"), url('/fonts/Poppins-Thin.woff') format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Light.woff2') format("woff2"), url('/fonts/Poppins-Light.woff') format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Regular.woff2') format("woff2"), url('/fonts/Poppins-Regular.woff') format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Medium.woff2') format("woff2"), url('/fonts/Poppins-medium.woff') format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-SemiBold.woff2') format("woff2"), url('/fonts/Poppins-semibold.woff') format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Bold.woff2') format("woff2"), url('/fonts/Poppins-bold.woff') format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-ExtraBold.woff2') format("woff2"), url('/fonts/Poppins-ExtraBold.woff') format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Black.woff2') format("woff2"), url('/fonts/Poppins-Black.woff') format("woff");
  font-weight: 900;
  font-style: normal; }
