$light-color: #fff !default;
$dark-color: #1D1B1B !default;
$black-color: #000000 !default;
$orange: #EC6608 !default;
$green: #43EFCE !default;
$grey-color: #333333 !default;
$red-color: #FF3B3D !default;

$thin: 200 !default;
$light: 300 !default;
$regular: 400 !default;
$medium: 500 !default;
$semibold: 600 !default;
$bold: 700 !default;
$extrabold: 800 !default;
$black: 900 !default;

$body-font: 'Poppins', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default;

$z-indexes: (base: 0, content: 2, menu: 4, modal: 6, menu-actions: 8, overlay: 10, cookies: 12 ) !default;

$breakpoints: (laptop: 'screen and ( max-width: 1480px )', tablet: 'screen and ( max-width: 980px )', mobile: 'screen and (max-width: 700px )') !default;
