.form {
  background-image: url("/img/contact_bg_stripe.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 104% 17px;
  height: auto;
  padding: 60px 0px;
  font-family: 'Poppins';

  .section {
    &__wrapper {
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 90%;
      max-width: 1200px;
      margin: 0px auto;
      display: flex;
      flex-direction: column; }
    &__container {
      width: 100%;
      display: flex;
      @include mq(mobile) {
        flex-direction: column; } }
    &__box {
      flex: 1 1 50%;
      width: 50%;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mq(mobile) {
        width: 100%; } } }
  form, label, input {
    width: 100%; }

  .formfield--100 {
    flex: 0 1 100%;
    border: 1px solid #DDDDDD;
    margin: 0px 0px 15px 0px;
    font-size: 14px;
    text-indent: 10px;
    background: #FFFFFF;
    color: $dark-color;
    font-weight: 500; }

  .formfield--75 {
    flex: 0 1 74%;
    border: 1px solid #DDDDDD;
    margin: 0px 0px 15px 0px;
    font-size: 14px;
    text-indent: 10px;
    background: #FFFFFF;
    color: $dark-color;
    font-weight: 500;
    width: 74%;
    @include mq(mobile) {
      width: 100%; } }

  .formfield--25 {
    flex: 0 1 25%;
    border: 1px solid #DDDDDD;
    margin: 0px 0px 15px 0px;
    font-size: 14px;
    text-indent: 10px;
    background: #FFFFFF;
    color: $dark-color;
    font-weight: 500;
    width: 24%;
    @include mq(mobile) {
      width: 100%; } }

  .form-field {
    border: 1px solid $grey-color;
    margin: 0px 0px 15px 0px;
    font-size: 14px;
    // text-indent: 10px
    background: #FFFFFF;
    color: $dark-color;
    font-weight: $medium;
    &::placeholder {
      color: $grey-color;
      font-weight: $medium;
      font-family: 'Poppins'; } }

  .form-input {
    &::placeholder {
      font-family: 'Poppins'; } }

  .form-field--50 {
    min-width: 49%;
    flex: 0 1 49%;
    @include mq(mobile) {
      width: 100%; } }

  .form-field-select {
    height: 40px;
    padding: 0px;
    text-indent: 0px;
    border: 1px solid #DDDDDD; }

  select {
    border-radius: 0px !important;
    font-size: 13px;
    flex: 0 1 10%;
    border-bottom: 1px solid #000000; }


  .form-field--25 {
    width: 24%;
    flex: 0 1 24%;
    @include mq(mobile) {
      width: 100%; } }

  input, select, option {
    background: transparent;
    min-height: 40px;
    height: 40px;
    border: 0px;
    // text-indent: 10px
    font-size: 16px;
    border-bottom: 1px solid #000;
    display: inline-flex;
    flex: 1 1 35%;
    margin-bottom: 30px;
    margin-right: 15px;
    font-weight: 300;
    color: #DDDDDD; }
  option {
    color: #1D1B1B; }

  input {
    &[type="checkbox"], &[type="radio"] {
      margin-right: 10px;
      min-height: inherit;
      height: inherit;
      flex: none;
      display: inline-flex;
      position: relative;
      top: 2px;
      border: 0px;
      margin-bottom: 15px;
      width: 13px; } }

  select {
    font-weight: $medium;

    &:not([multiple]) {
      -moz-appearance: none;
      background-position: right 15px center;
      background-repeat: no-repeat;
      background-image: url("/img/dropdown.svg");
      background-color: transparent;
      width: 100%;
      font-weight: 500;
      border: 0px;
      padding-bottom: 2px;
      color: #1B1D1D;
      font-size: 14px;
      border: inherit;
      text-indent: 4px;
      appearance: none; } }

  .form-field:first-child {
    margin-right: 100%; }

  textarea {
    height: 148px;
    padding-top: 8px;
    font-family: 'Poppins';
    width: 100%; } }

.term__label {
  display: flex;
  justify-content: flex-start; }

.term__text {
  font-size: 13px;
  &--small {
    font-size: 0.8rem; } }

.required {
  color: $black-color;
  font-size: 1.3rem;
  line-height: 1.3;
  font-weight: 300;
  margin: 15px auto; }

.btn_txt {
  color: #ffffff;
  text-transform: uppercase;
  background-color: #1D1B1B;
  padding: 6px 10px;
  text-decoration: none;
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1; }
