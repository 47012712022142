@import './lib/swiper.sass';

.swiper-container {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  @include mq(mobile) {
    pading-top: 60px; }
  .swiper-wrapper {
    display: flex;
    width: auto;
    height: 100%; }
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto; }

  .swiper-pagination {
    color: $light-color;
    margin: 0 auto;
    height: 20px;
    position: inherit;
    bottom: -20px;
    @include mq(mobile) {
      height: 55px; } }

  .swiper-button-prev,
  .swiper-button-next {
    color: $light-color; }

  .swiper-pagination {
    bottom: 0;
    @include mq(mobile) {
      bottom: -5px; } }
  .swiper-pagination-bullet {
    border: 1px solid $light-color; }
  p {
    color: $light-color; }
  .swiper-button-disabled {
    display: none; }

  .swiper-button-prev:after {
    content: '';
    background: url("/img/arrow-left.png") no-repeat;
    left: -9px;
    @include mq(mobile) {
      left: 0px; } }

  .swiper-button-next:after {
    content: '';
    background: url("/img/arrow-right.png") no-repeat;
    right: -11px;
    @include mq(mobile) {
      right: 0px; } }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    width: 20px;
    height: 30px;
    position: absolute;
    background-size: contain;
    @include mq(mobile) {
      bottom: -260px;
      height: 35px; } } }
